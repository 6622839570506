<template>
  <div>
    <v-row class="my-1">
      <v-col cols="8">
        <div class="title text-capitalize">
          {{ $t(step.title, '').replace(/Starta /, '') }}
        </div>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-text-field
          v-if="step.state !== 'upptag'"
          placeholder="--:--"
          type="time"
          dense
          outlined
          hide-details
          :error="!isValid"
          v-model="currentTimestamp"
          @change="onChange"
        />
        <span v-else>
          {{ currentTimestamp }}
        </span>
      </v-col>
    </v-row>

    <v-row class="my-0 text-right red--text" v-if="!isValid">
      <v-col class="py-0 mt-0 mb-1">
        <small>
          Fältet innehåller fel, datan kan inte sparas!
        </small>
      </v-col>
    </v-row>

    <v-row class="mt-0 mb-1" v-if="step.state === 'upptag'">
      <v-col>
        <v-simple-table fixed-header dense>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">Från</th>
                <th class="text-left">Till</th>
                <th class="text-left">Drev</th>
                <th class="text-left">Tappt</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr :key="`tappt-${id}`" v-for="(tappt, id) in tapptList">
                <td>
                  <v-text-field
                    ref="timestamp"
                    placeholder="--:--"
                    type="time"
                    dense
                    outlined
                    hide-details
                    :error="!tapptList[id].from_valid"
                    v-model="tappt.from"
                    @change="onTappt(id)"
                  />
                </td>
                <td>
                  <v-text-field
                    ref="timestamp"
                    placeholder="--:--"
                    type="time"
                    dense
                    outlined
                    hide-details
                    :error="!tapptList[id].to_valid"
                    v-model="tappt.to"
                    @change="onTappt(id)"
                  />
                </td>
                <td>
                  <span class="green--text" v-if="tappt.drev">{{
                    tappt.drev
                  }}</span>
                  <span v-else>-</span>
                </td>
                <td>
                  <span
                    v-if="tappt.tappt"
                    :class="[tappt.tappt < 5 ? 'text--disabled' : 'red--text']"
                  >
                    {{ tappt.tappt }}
                  </span>
                  <span v-else>-</span>
                </td>
                <td>
                  <v-btn
                    icon
                    color="red white--text"
                    v-if="id < tapptList.length - 1"
                    @click="removeTappt(id)"
                  >
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Totalt</strong>
                </td>
                <td />
                <td>
                  <span class="green--text" v-if="totalDrev">{{
                    totalDrev
                  }}</span>
                  <span v-else>-</span>
                </td>
                <td>
                  <span class="red--text" v-if="totalTappt">{{
                    totalTappt
                  }}</span>
                  <span v-else>-</span>
                </td>
                <td />
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row class="my-0 text-right orange--text" v-if="hasIgnoredTappt">
          <v-col class="py-0 mt-0 mb-1">
            <strong>Tappt under 5 minuter räknas ej</strong>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <v-divider />

    <template v-if="step.state === 'stop'">
      <v-row class="my-1">
        <v-col cols="8">
          <div class="title text-capitalize">
            Skallfrekvens
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-text-field
            type="text"
            :value="barkFreq"
            @change="onChangeBarkFreq"
            hide-details
            outlined
            dense
          />
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="my-1">
        <v-col cols="8">
          <div class="title text-capitalize">
            Loss tid
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <span v-html="totalTime" />
        </v-col>
      </v-row>

      <v-divider />
    </template>

    <template v-if="evaluation">
      <evaluation-inputs
        ref="evaluation"
        :radios-as-dropdown="true"
        :inputs="evaluation.inputs"
        :title="evaluation.title"
        :inline="true"
        :divider="true"
        :hide-details="true"
        :values="roundStep.results"
        @change="onChange"
      />
    </template>

    <template v-if="step.state === 'upptag'">
      <v-row class="my-1">
        <v-col cols="8">
          <div class="title text-capitalize">
            Sista drevskall
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <span v-if="cleanList.length > 0">
            {{ cleanList[cleanList.length - 1].to }}
          </span>
          <span v-else>--:--</span>
        </v-col>
      </v-row>

      <v-divider />
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import EvaluationInputs from '@/components/evaluation/inputs.vue';

export default {
  components: {
    EvaluationInputs,
  },

  props: {
    step: {
      type: Object,
      required: true,
    },
    additionalSteps: {
      type: Array,
      default: [],
    },
    roundTimestamp: {
      type: Number,
      required: true,
    },
    roundStep: {
      type: Object,
      default: () => ({}),
    },
    roundId: {
      type: Number,
      required: true,
    },
    totalTime: {
      type: String,
      default: '--:--',
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    results: {
      type: Array,
      default: [],
    },
  },

  data: () => ({
    tapptList: [],
    hasIgnoredTappt: false,
    currentTimestamp: '--:--',
  }),

  mounted() {
    this.$watch('timestamp', this.updateTapptList);
    this.$watch('additionalSteps', this.updateTapptList, { deep: true });

    this.updateTapptList();
  },

  computed: {
    barkFreq() {
      const res = this.results.find((r) => r.type === 'bark_frequency');
      return res ? res.value : null;
    },

    isValid() {
      return (
        _.sum([
          this.timeValidate(this.currentTimestamp) ? 0 : 1,
          ...this.tapptList.map((t) => (t.to_valid && t.from_valid ? 0 : 1)),
        ]) === 0
      );
    },

    cleanList() {
      return this.tapptList.filter((t) => !(!t.from || !t.to));
    },

    resultList() {
      // Transform back to tappt states

      return {
        timestamp: this.timestampFromString(this.currentTimestamp),
        tappt: this.cleanList.map((t, i) => {
          const next = this.cleanList[i + 1]
            ? this.timestampFromString(this.cleanList[i + 1].from)
            : this.max;

          return {
            timestamp: this.timestampFromString(t.to),
            duration: next - this.timestampFromString(t.to),
          };
        }),
      };
    },

    totalDrev() {
      return (
        _.sumBy(this.cleanList, (t) => t.drev) +
        _.sumBy(
          this.cleanList.filter((t) => t.tappt < 5),
          (t) => t.tappt,
        )
      );
    },

    totalTappt() {
      return _.sumBy(
        this.cleanList.filter((t) => t.tappt >= 5),
        (t) => t.tappt,
      );
    },

    evaluation() {
      return this.step.evaluation;
    },

    timestamp() {
      if (!this.roundStep.timestamp) {
        return '--:--';
      }

      return moment(this.roundStep.timestamp).format('HH:mm');
    },
  },

  methods: {
    updateTapptList() {
      this.currentTimestamp = this.timestamp;
      this.hasIgnoredTappt = false;

      if (this.step.state !== 'upptag') {
        return;
      }

      const list = _.cloneDeep(this.tapptList);

      if (!list[0]) {
        list[0] = {};
      }

      list[0].from_t = this.roundStep.timestamp;
      list[0].from = moment(this.roundStep.timestamp).format('HH:mm');
      list[0].to = null;

      const tappt = this.additionalSteps.filter((s) => s.state === 'tappt');

      tappt.forEach((t, current) => {
        const next = current + 1;
        list[current].to = moment(t.timestamp).format('HH:mm');

        const duration = t.results.find((r) => r.type === 'tappt.duration')
          .value;
        list[current].tappt = Math.round(duration / 60000);
        list[current].drev = Math.round(
          (t.timestamp - list[current].from_t) / 60000,
        );

        if (list[current].tappt < 5 && list[current].tappt !== 0) {
          this.hasIgnoredTappt = true;
        }

        if (!list[next]) {
          list[next] = {};
        }

        list[next].from_t = t.timestamp + duration;
        list[next].from = moment(t.timestamp + duration).format('HH:mm');
        list[next].to = null;
        list[next].to_valid = true;
        list[next].from_valid = true;
      });

      if (list.length > 1) {
        list[list.length - 2].tappt = null;
      }

      list[list.length - 1] = {};

      this.tapptList = this.validateTapptList(list);
    },

    timestampFromString(ts, fallback = 0) {
      if (typeof ts !== 'string') {
        return fallback;
      }

      const t = ts.split(':');
      if (isNaN(t[0]) || isNaN(t[1])) {
        return 0;
      }

      return (
        moment(this.roundTimestamp)
          .set({
            h: t[0],
            m: t[1],
            s: 0,
          })
          .unix() * 1000
      );
    },

    timeValidate(v) {
      if (typeof v !== 'string') {
        return false;
      }

      const timestamp = this.timestampFromString(v);

      // console.log(
      //   v,
      //   moment(this.roundTimestamp).format('YYYY-MM-DD'),
      //   moment(this.min).format('YYYY-MM-DD hh:mm'),
      //   moment(timestamp).format('YYYY-MM-DD hh:mm'),
      //   moment(this.max).format('YYYY-MM-DD hh:mm'),
      //   timestamp <= this.max,
      //   timestamp >= this.min,
      //   (this.max === 0 || timestamp <= this.max) &&
      //     (this.min === 0 || timestamp >= this.min),
      // );

      return (
        (this.max === 0 || timestamp <= this.max) &&
        (this.min === 0 || timestamp >= this.min)
      );
    },

    onTappt(id) {
      const current = this.tapptList[id];

      if (!current.from || !current.to) {
        if (!current.from && !current.to) {
          this.tapptList.splice(id, 1);
        }
      }

      if (id >= this.tapptList.length - 1) {
        this.tapptList.push({
          from_t: 0,
          from: null,
          to: null,
        });
      }

      this.tapptList = this.validateTapptList(this.tapptList);
      this.currentTimestamp = this.cleanList[0] ? this.cleanList[0].from : null;

      Vue.nextTick(() => {
        if (!this.isValid) {
          return;
        }

        this.$emit('createStepsFromTappt', this.roundId, this.resultList);
      });
    },

    validateTapptList(list) {
      return list
        .sort((a, b) => {
          if (!a.from && !a.to) {
            return 1;
          }
          if (!b.from && !b.to) {
            return -1;
          }

          const a_from_t = this.timestampFromString(a.from, this.max);
          const b_from_t = this.timestampFromString(b.from, this.max);
          return a_from_t - b_from_t;
        })
        .map((t, i) => {
          if (!t.from && !t.to) {
            t.to_valid = true;
            t.from_valid = true;
            return t;
          }

          let prev_t = 0;
          let next_t = this.max;

          if (i > 0) {
            prev_t = this.timestampFromString(list[i - 1].to, prev_t);
          }
          if (i < list.length && list[i + 1]) {
            next_t = this.timestampFromString(list[i + 1].from, next_t);
          }

          const from_t = this.timestampFromString(t.from);
          const to_t = this.timestampFromString(t.to);

          t.to_valid =
            to_t >= this.min &&
            to_t <= this.max &&
            to_t <= next_t &&
            from_t <= to_t;
          t.from_valid =
            from_t <= this.max &&
            from_t >= this.min &&
            from_t >= prev_t &&
            from_t <= to_t;

          return t;
        });
    },

    removeTappt(id) {
      this.tapptList.splice(id, 1);

      this.tapptList = this.validateTapptList(this.tapptList);
      this.currentTimestamp = this.cleanList[0] ? this.cleanList[0].from : null;

      Vue.nextTick(() => {
        if (!this.isValid) {
          return;
        }

        this.$emit('createStepsFromTappt', this.roundId, this.resultList);
      });
    },

    onChangeBarkFreq(value) {
      this.$emit('onChangeResult', this.roundId, 'bark_frequency', value);
    },

    onChange() {
      if (!this.isValid) {
        return;
      }

      const timestamp = this.timestampFromString(this.currentTimestamp);

      let results = [];

      if (this.evaluation) {
        results = this.$refs.evaluation.results();
      }

      // console.log(
      //   this.timeValidate(this.currentTimestamp),
      //   this.step.state,
      //   timestamp,
      //   results,
      // );

      this.$emit('onChange', this.roundId, {
        state: this.step.state,
        timestamp,
        results,
      });
    },
  },
};
</script>

<style>
.title {
  font-size: 14px;
  font-weight: bold;
  flex-grow: 1;
}

tbody > tr > td,
thead > tr > th {
  padding-top: 4pt !important;
  padding-bottom: 4pt !important;
  padding-left: 0pt !important;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
