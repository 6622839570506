<template>
  <layout-single v-if="registration">
    <template #content>
      <v-row>
        <v-col>
          <v-text-field
            class="my-4 px-8"
            type="date"
            @change="syncTestDate"
            :value="testDate()"
            hide-details
            outlined
          />
        </v-col>
      </v-row>

      <v-divider class="mx-8" />

      <v-container class="content px-8 py-4" v-if="registration">
        <v-expansion-panels>
          <v-expansion-panel :key="number" v-for="(round, number) in rounds">
            <v-expansion-panel-header color="green white--text">
              <span class="font-weight-bold title">
                {{ $t('Round') }} {{ number + 1 }}
                <v-chip
                  class="ml-2"
                  color="font-weight-regular white green--text"
                  v-if="roundTime(number) > 0"
                >
                  <span
                    :inner-html.prop="roundTime(number) | durationFullNoSemicolonsZero"
                  />
                </v-chip>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <chase-step
                v-for="step in test.steps"
                :key="step.state"
                :step="step"
                :round-timestamp="testDateInt(number)"
                :round-step="currentStep(round, step.state)"
                :additional-steps="getAdditional(number, step.state)"
                :round-id="number"
                :total-time="elapsedBySteps('start', 'stop', round) | durationFullNoSemicolonsZero"
                :max="timestampStep(number, step.state, 1)"
                :min="timestampStep(number, step.state, -1)"
                :results="round.results"
                @createStepsFromTappt="onChangeUpptag"
                @onChangeResult="onChangeResult"
                @onChange="onChange"
              />

              <v-btn
                class="mt-4"
                outlined
                block
                color="red"
                @click="removeRound(number)"
              >Ta bort Släpp {{ number + 1 }}</v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn
          class="mt-4"
          block
          color="green white--text"
          @click="addRound"
        >+ Lägg till Släpp {{ rounds.length + 1 }}</v-btn>
      </v-container>
    </template>

    <template #footer>
      <v-container class="white pa-0">
        <v-container class="px-8 white black--text">
          <strong>Summering</strong>
        </v-container>

        <v-divider class="grey lighten-1" />

        <v-container class="px-8 grey lighten-3 black--text">
          <v-row>
            <v-col>
              <strong>Provet avbröts kl.</strong>
            </v-col>
            <v-col class="text-right">
              {{ testEnd }}
            </v-col>
          </v-row>
        </v-container>

        <v-divider class="grey lighten-1" />

        <v-container class="px-8 grey lighten-3 black--text">
          <v-row>
            <v-col>
              <strong>Inkallningar med pejl</strong>
            </v-col>
            <v-col class="text-right">
              {{ pejlCount }}
            </v-col>
          </v-row>
        </v-container>

        <v-divider class="grey lighten-1" />

        <v-container class="px-8 grey lighten-3 black--text">
          <v-row>
            <v-col>
              <strong>Hunden lös</strong>
            </v-col>
            <v-col class="text-right">
              <div v-if="totalTime">
                <strong :inner-html.prop="totalTime | durationFullNoSemicolonsZero" />
                <span>({{ Math.round(totalTime / 60000) }} min)</span>
              </div>
              <span v-else>--:--</span>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </template>
  </layout-single>
</template>

<script>
import LayoutSingle from '@/components/layout-single'
import chaseStep from './chaseStep'
import _ from 'lodash'
import moment from 'moment'

const _24H = 86400000

export default {
  name: 'chaseEvaluation',

  components: {
    LayoutSingle,
    chaseStep,
  },

  computed: {
    testEnd() {
      const last = this.lastRound;

      if (!last) {
        return '--:--';
      }

      const kopplad = last.steps.find((s) => s.state === 'stop');

      if (!kopplad) {
        return '--:--';
      }

      return moment(kopplad.timestamp).format('HH:mm');
    },

    totalTime() {
      return _.sum(this.rounds.map((r) => this.elapsedBySteps('start', 'stop', r)))
    },

    pejlCount() {
      return _.sum(this.rounds.map((r) => {
        const kopplad = r.steps.find((s) => s.state === 'stop');
        if (!kopplad) {
          return 0;
        }

        const res = kopplad.results.find((s) => s.type === 'hunting_pejl')

        return res ? res.value : 0;
      }));
    },

    lastRound() {
      const rounds = this.rounds.map((r, i) => {
        const kopplad = r.steps.find((s) => s.state === 'stop')

        return {
          round: r,
          time: kopplad ? kopplad.timestamp : 0
        }
      });

      const last = _.last(rounds.sort((a, b) => a.time - b.time));

      return last ? last.round : null;
    },

    registration() {
      return this.$store.state.rx.registrations.find(
        (e) => e.id === this.$route.params.registrationId,
      );
    },

    offset() {
      return (new Date(this.testDateInt())).getTimezoneOffset()
    },

    event() {
      return this.$store.state.rx.events.find(
        (e) => e.id === this.$route.params.eventId,
      );
    },

    test() {
      return this.$store.state.rx.tests.find((t) => t.type === this.event.type);
    },

    steps() {
      return this.test.steps;
    },

    rounds() {
      return this.registration.rounds;
    },
  },

  mounted() {
    // this.rounds.forEach((round, idx) => {
    //   this.syncRoundDate(idx)
    // })

    this.$store.commit('setNavigation', {
      show: true,
      title: 'Inmatning av Drev',
      to: `/event/${this.$route.params.eventId}/registration/${this.$route.params.registrationId}`,
    });
  },

  methods: {
    currentStep(round, state) {
      return round.steps.find((r) => r.state === state);
    },

    timestampStep(roundId, state, offset) {
      const round = this.rounds[roundId]
      let index = this.steps.findIndex((s) => s.state === state);
      const step = this.steps[index + offset]
      const maxVal = this.testDateInt() + _24H
      const minVal = this.testDateInt()

      if (!step) {
        return offset > 0 ? maxVal : minVal
      }

      index = round.steps.findIndex((r) => r.state === step.state)

      if (index < 0) {
        return offset > 0 ? maxVal : minVal
      }

      if (round.steps[index].state === 'upptag' && offset < 0) {
        const tappt = this.getAdditional(roundId, 'upptag').filter((s) => s.state === 'tappt')
        if (tappt.length !== 0) {
          const time = moment(tappt[tappt.length - 1].timestamp + (this.offset * 60000))
          return moment.utc(this.testDateInt()).set({
            h: time.hours(),
            m: time.minutes(),
            s: 0
          }).unix() * 1000
        }
      }

      const time = moment(round.steps[index].timestamp + (this.offset * 60000))
      return moment.utc(this.testDateInt()).set({
        h: time.hours(),
        m: time.minutes(),
        s: 0
      }).unix() * 1000
    },

    getAdditional(roundId, state) {
      return _.flatten(
        this.test.additional_steps
          .filter((s) => (s.when ? s.when.includes(state) : false))
          .map((s) => {
            return this.rounds[roundId].steps.filter(
              (r) => r.state === s.state,
            );
          }),
      );
    },

    elapsedBySteps(from, to, current) {
      if (!current) {
        return
      }

      const ss = current.steps.find((s) => s.state === from);
      const es = current.steps.find((s) => s.state === to);

      if (!ss) {
        return 0;
      }

      let end = this.elapsed;

      if (es) {
        end = es.elapsed;
      }

      let calc = end - ss.elapsed;

      const step = this.steps.find((s) => s.state === from);

      if (step.modifiers !== undefined) {
        calc -= _.sum(
          step.modifiers.map((m) => {
            const steps = current.steps.filter((s) => s.state === m.state);
            const total = steps.map((s) => {
              let duration = this.timestamp - s.timestamp;

              if (s.results) {
                const result = s.results.find(
                  (r) => r.type === `${s.state}.duration`,
                );

                if (result) {
                  duration = result.value;
                }

                return duration >= m.limit * 1000 ? duration : 0;
              }

              return duration;
            });

            return _.sum(total);
          }),
        );
      }

      return calc;
    },

    globalElapsedBySteps(from, to) {
      return _.sum(
        this.registration.rounds.map((r) => this.elapsedBySteps(from, to, r)),
      );
    },

    roundTime(roundId) {
      const round = this.rounds[roundId];
      if (!round) {
        return 0
      }

      const upptag = round.steps.find((s) => s.state === 'upptag')

      if (!upptag || !upptag.timestamp) {
        return 0
      }

      const tappt = round.steps.filter((s) => s.state === 'tappt')
      const last = _.last(tappt)

      if (!last) {
        return 0;
      }

      let calc = last.timestamp - upptag.timestamp

      const step = this.steps.find((s) => s.state === 'upptag');
      calc -= _.sum(
        step.modifiers.map((m) => {
          const steps = round.steps.filter((s) => s.state === m.state);
          const total = steps.map((s, i) => {
            if (i === steps.length - 1) {
              return 0; // ignore last tappt
            }

            let duration = this.timestamp - s.timestamp;

            if (s.results) {
              const result = s.results.find(
                (r) => r.type === `${s.state}.duration`,
              );

              if (result) {
                duration = result.value;
              }

              return duration >= m.limit * 1000 ? duration : 0;
            }

            return duration;
          });

          return _.sum(total);
        }),
      );

      return calc
    },

    testDateInt() {
      const res = this.registration.results.find((r) => r.type === 'date')
      return ((res ? res.value : this.registration.scheduled_at) / 86400) * 86400
    },

    testDate(roundId) {
      const value = this.testDateInt(roundId)
      return moment.utc(value).format('YYYY-MM-DD')
    },

    async addRound() {
      try {
        await this.registration.atomicUpdate((reg) => {
          reg.rounds.push({
            results: [],
            steps: []
          })
          return reg
        })
      } catch (err) {
        console.log(err);
      }
    },

    async removeRound(roundId) {
      try {
        await this.registration.atomicUpdate((reg) => {
          reg.rounds = reg.rounds.filter((r, id) => id !== roundId)
          return reg
        })
      } catch (err) {
        console.log(err);
      }
    },

    async syncTestDate(value = null) {
      const date = (
        value ?
          moment.utc(value) :
          moment.utc(this.registration.scheduled_at)
      ).set({
        h: 0,
        m: 0,
        s: 0
      })

      try {
        await this.registration.atomicUpdate((reg) => {
          const res = reg.results.findIndex((r) => r.type === 'date')

          if (res < 0) {
            reg.results.push({
              type: 'date',
              value: date.unix() * 1000,
              value_text: '0',
            })
          } else {
            reg.results[res].value = date.unix() * 1000
          }

          reg.rounds.forEach((round) => {
            round.steps = round.steps.map((s) => {
              const time = moment.utc(s.timestamp)
              const check = moment.utc(s.timestamp).set({
                h: 0,
                m: 0,
                s: 0,
              })

              if (check.unix() !== date.unix()) {
                s.timestamp = moment.utc(date).set({
                  h: time.hours(),
                  m: time.minutes(),
                  s: 0
                }).unix() * 1000
                console.log(s.timestamp)
              }

              return s
            }).sort((a, b) => a.timestamp - b.timestamp).map((s, si) => ({
              ...s,
              index: si,
              elapsed: this.$nosework.elapsed(s, round.steps, si),
            }))
          })

          return reg;
        })
      } catch (err) {
        console.log(err);
      }
    },

    async onChangeUpptag(roundId, resultList) {
      const step = {
        state: 'upptag',
        timestamp: resultList.timestamp,
        results: [],
      }

      try {
        await this.registration.atomicUpdate((reg) => {
          const round = reg.rounds[roundId];

          let id = -1;

          if (this.test.steps.findIndex((s) => s.state === step.state) > -1) {
            id = round.steps.findIndex((s) => s.state === step.state);
          }

          if (id < 0) {
            step.index = round.steps.push(step);
          } else {
            round.steps[id] = {
              ...round.steps[id],
              ...step,
            };
          }

          round.steps = [
            ...round.steps.filter((s) => s.state !== 'tappt'),
            ...resultList.tappt.map((t) => ({
                state: 'tappt',
                timestamp: t.timestamp,
                results: [
                  {
                    type: 'tappt.duration',
                    value: t.duration,
                    value_text: '',
                    comment: '',
                  }
                ]
            }))
          ].sort((a, b) => a.timestamp - b.timestamp).map((s, si) => {
            return {
              ...s,
              index: si,
              elapsed: this.$nosework.elapsed(s, round.steps, si),
            };
          });

          return reg;
        });
      } catch (err) {
        console.log(err);
      }
    },

    async onChangeResult(roundId, type, value) {
      try {
        await this.registration.atomicUpdate((reg) => {
          const round = reg.rounds[roundId];
          const res = round.results.find((r) => r.type === type)

          if (res) {
            res.value = Number(value)
            res.value_text = `${value}`
          } else {
            round.results.push({
              type,
              value: Number(value),
              value_text: `${value}`,
              comment: ''
            })
          }

          return reg
        });
      } catch (err) {
        console.log(err);
      }
    },

    async onChange(roundId, step) {
      try {
        const time = moment(step.timestamp + (this.offset * 60000))
        step.timestamp = moment.utc(this.testDateInt()).set({
          h: time.hours(),
          m: time.minutes(),
          s: 0
        }).unix() * 1000

        await this.registration.atomicUpdate((reg) => {
          const round = reg.rounds[roundId];

          let id = -1;

          if (this.test.steps.findIndex((s) => s.state === step.state) > -1) {
            id = round.steps.findIndex((s) => s.state === step.state);
          }

          if (id < 0) {
            step.index = round.steps.push(step);
          } else {
            round.steps[id] = {
              ...round.steps[id],
              ...step,
            };
          }

          round.steps.sort((a, b) => a.timestamp - b.timestamp);

          round.steps = round.steps.map((s, si) => {
            return {
              ...s,
              index: si,
              elapsed: this.$nosework.elapsed(s, round.steps, si),
            };
          });

          return reg;
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  padding: 0px;
  margin: 0px;
  text-align: center !important;
}
.content {
  margin-bottom: 180pt;
}
</style>
